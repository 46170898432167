import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgLogo from "../images/logo-white-alpha-large.png"
import imgCoach from "../images/coach-blue-blur.jpg"
import imgOffer from "../images/bounce-back-free-scanners-email.jpg"
import imgProfileKevinMortlock from "../images/profile-kevin-mortlock-circle.png"
import imgProfileGrahamBethell from "../images/profile-graham-bethell-circle.png"
import "./index.css";
import "./case-study.css";
import "./article.css";
import Helmet from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Bounce back offer - FREE bus ticket and bus pass scanners" />
        <Helmet>
          <script src="https://kit.fontawesome.com/d186c5838e.js" crossorigin="anonymous"></script>
        </Helmet>
        <div>
            <div>
              <div className='header-container' style={{ maxWidth: '100%'}}>
                <div className='header' style={{ maxWidth: '100%'}}>
                  <div style={{ maxWidth: '100%', margin: '0 auto'}}>
                    <Link to='/'><img style={{ maxWidth: `200px`, margin: 0 }} src={imgLogo} /></Link>
                    <div className='nav'>
                      <Link to='/packages/' className='nav-link'>Packages</Link>
                      <Link to='/case-studies/' className='nav-link'>Case studies</Link>
                      <Link to='/about/' className='nav-link'>About</Link>
                      <Link to='/contact/' className='nav-link'>Contact</Link>
                      <span className='nav-item desktop-only'>Sales: 0333 344 9868</span>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', backgroundImage: `url(${imgCoach})`, backgroundColor: '#fbfbfb', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>

                <div className="blog" style={{ marginTop: 100, marginBottom: 100,  maxWidth: 754 }}>
                  <div className='headline-block blog-inner headline-block-offer'>
                    {/* <h1 className="blog-headline" style={{ textAlign: 'left' }}>FREE ticket scanners for DTE members</h1> */}
                    <img style={{ display: 'block' }} src={imgOffer} alt="" />
                    <p className="offer-highlight"><strong>Bounce back when schools return in 2021 with our FREE bus pass scanners offer. </strong>Available with our <Link to='/simple-package/'>Simple</Link> and <Link to='/complete-package/'>Complete</Link> packages starting in 2021. Offer ends March 31st 2021 and whilst stock lasts. <Link to='/bounce-back-offer/#register'>Claim offer</Link>.</p>
                  </div>
                  <div style={{ maxWidth: 715 }} className='blog-inner'>
                    {/* <h2>About ShuttleID</h2>
                    <p>ShuttleID is a cost-effective cashless ticketing system that makes it easy to manage home-to-school transport.</p>
                    <p>We use consumer grade hardware so simple ticket issuing and scanning becomes affordable on your school services, without the hassle of manually managing payments and physical passes.</p> */}

                    <h3>Don't give parents another excuse to use their car!</h3>
                    <p><strong>With an increase in the number of parents now driving their own kids into school, when was the last time you gave your home-to-school services a health check? Are they still adding as much value to parents, schools and local authorities as they used to?</strong></p>
                    <p><strong>Here's 9 ways ShuttleID can help your school services bounce back and stop parents using their cars:</strong></p>

                    <h4>1) Cashless</h4>
                    <blockquote style={{ background: '#fff4c7', padding: '.5em' }}><i>"Is it safe to handle cash? How can I get the exact change every single day? Will my child actually spend it on the bus? What if a bully steals their bus fare?"</i></blockquote>
                    <p>Sell your school bus tickets and passes online. It's easier for parents with the added benefit drivers can't misplace the cash and it doesn't require trips to the bank.</p>

                    <h4>2) Track and trace</h4>
                    <blockquote style={{ background: '#fff4c7', padding: '.5em' }}><i>"How will the bus company know if someone with Covid-19 travelled with my child?"</i></blockquote>
                    <p>Despite great progress with the Covid vaccines, it's anticipated booster jabs and new virus variants may mean we won't be back to complete normality for a while. Give parents confidence it's still safe to travel through data. Using scan logs, you retain records of who travelled, when and who with. Combined with CCTV it gives you a robust contact tracing system.</p>

                    <h4>3) SMS service alerts</h4>
                    <blockquote style={{ background: '#fff4c7', padding: '.5em' }}><i>"What if the bus doesn't turn up? I don't want to risk my child being stuck at the bus stop by themselves, it's not safe!"</i></blockquote>
                    <p>Highly visible and critical messaging delivered instantly for service disruption to only those that need to see it. Text messages are unrivalled in getting your important message seen right away.</p>

                    <h4>4) Convenient</h4>
                    <blockquote style={{ background: '#fff4c7', padding: '.5em' }}><i>"I've left it too late to get a bus pass sorted, I hope the driver will understand and still let my child on. Maybe we'll just try next term instead?"</i></blockquote>
                    <p>Parents can leave it as late as minutes before boarding to order their bus pass. When it's paid online and delivered instantly there's no reason why this has to be problem today. </p>

                    <h4>5) Guarantee value</h4>
                    <blockquote style={{ background: '#fff4c7', padding: '.5em' }}><i>"The parents talk and we know there are other kids sneaking on for free. We'll keep quiet but it's not really fair on the rest of us?"</i></blockquote>
                    <p>With each passenger required to scan their pass, this protects the integrity of your service and represents a fair deal to customers so no one feels ripped off.</p>

                    <h4>6) Safe</h4>
                    <blockquote style={{ background: '#fff4c7', padding: '.5em' }}><i>"At least if I drive I know my child is able to sit and has a seatbelt. I also know who they're travelling with."</i></blockquote>
                    <p>With strict controls over capacity, you're never at risk of overcrowding with ShuttleID as tickets will sell out first. Scan logs give extra assurance to schools and parents that overcrowding hasn't occurred. Ticket scanning helps you guarantee only those permitted are able to travel.</p>

                    <h4>7) Flexible</h4>
                    <blockquote style={{ background: '#fff4c7', padding: '.5em' }}><i>"I drive my child to school in the morning but I struggle to pick them up in the afternoon. I guess I can ask their friend's parents to pick him up?"</i></blockquote>
                    <p>Offer as much flexibility as desired, with a system that can ensure tickets and passes can only be used when intended. With spare seats left on your services, you can open these up to customers with infrequent travel needs to ensure you maximise revenue.</p>

                    <h4>8) Child proof</h4>
                    <blockquote style={{ background: '#fff4c7', padding: '.5em' }}><i>"My child lost their pass again, I hope the driver lets them on OK? It seems like I'm forking out for a replacement bus pass nearly every other week!"</i></blockquote>
                    <p>Kids lose things. But there is one thing they won't let out of their sight - their mobile phones. Give the kids what they want: a bus pass that works on their phone. The benefit to mum and dad is pass replacement fees are history because they'll now have 24/7 access to a copy, which they can also print out.</p>

                    <h4>9) Money is safe</h4>
                    <blockquote style={{ background: '#fff4c7', padding: '.5em' }}><i>"I'm worried if I pay for the year or even term, there's a chance the company might go under and I'll be out of pocket. I can't afford the risk."</i></blockquote>
                    <p>With the industry in the shape it is currently, it's an understandable worry that some parents may have. Standing orders and direct bank transfers carry risk as they offer no financial protection to parents. With ShuttleID, payments can be made by card, offering protection in the event the operator goes bust.</p>


                    {/* <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileGrahamBethell} alt="Graham Bethell" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                      <p className='case-study__profile-quote__author' style={{ marginBottom: 20 }}>Graham Bethell, South Staffs Coach Hire</p>
                      <p><strong>"Having just set up ShuttleID I can honestly say it's a brilliant cost effective system. Ideal for coach operators wanting a home to school ticket system without the huge expense of hardware, but still gives better control of ticket issuing and usage."</strong></p>
                      <p><strong>"Over the weekend we have sold 30 tickets on one service without any input from ourselves. That was a weeks work last year!"</strong></p>

                      </div>
                    </div> */}

                    <div className='case-study__profile-quote'>

                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileKevinMortlock} alt="Kevin Mortlock" />

                      </div>
                      <div className='case-study__profile-quote__quote'>

                        <h3 style={{ color: 'green' }}>Patronage up 15% despite pandemic</h3>
                        <p><strong>"Customers have commented how quick and easy it is to buy tickets. Chris at ShuttleID has been very helpful and has made the transition very easy for us."</strong></p>
                        <p><strong>"The most important thing about this system is that our back office work has already reduced, saving us valuable time. We won’t be going back to our old system, which I thought was pretty much up to date but now looks archaic!"</strong></p>
                        <p className='case-study__profile-quote__author' style={{ marginBottom: 20 }}>Kevin Mortlock, Keane Travel</p>
                      </div>
                    </div>

                    <h2 id="register" style={{ textAlign: 'center' }}>How to claim your offer</h2>
                    <p style={{ textAlign: 'center' }}>Complete your details below to register your interest in our bounce back offer for <strong>FREE bus pass scanners</strong> on your school services in 2021.</p>
                    <form action="/bounce-back-offer-requested/" name="bounce-back-offer" method="POST" className="pricing-form" data-netlify="true" data-netlify-honeypot="bot-field">
                      <p style={{ display: 'none' }}>
                        <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                      </p>
                      <div className="pricing-form__group">
                        <label>Name</label>
                        <input name="name" required className="pricing-form__input" />
                      </div>
                      <div className="pricing-form__group">
                        <label>Company</label>
                        <input name="company" required className="pricing-form__input" />
                      </div>
                      <div className="pricing-form__group">
                        <label>Email</label>
                        <input name="email" type="email" required className="pricing-form__input" />
                      </div>
                      <div className="pricing-form__group">
                        <label>Phone</label>
                        <input name="phone" required className="pricing-form__input" />
                      </div>
                      <input type="hidden" name="form-name" value="bounce-back-offer" />
                      <p style={{ textAlign: 'center', paddingTop: '1em' }}>
                        <button className="btn" type="submit">Register interest <i className="fas fa-arrow-right"></i></button>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </Layout>
    );
  }
};

export default BlogPage